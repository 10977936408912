<template>
  <li class="splide__slide">
    <slot></slot>
  </li>
</template>

<script>
	export default {
		name: 'SplideSlide',
	};
</script>